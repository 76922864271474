import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children }) => {
    const { keycloak } = useKeycloak();

    console.log('Inside PrivateRoute:', keycloak);
    console.log('Authenticated:', keycloak.authenticated);

    if (!keycloak.authenticated) {
        console.warn('User is not authenticated. Redirecting to /login');
        return <Navigate to="/login" replace />;
    }

    console.log('User authenticated. Rendering children.');
    return children;
};

export default PrivateRoute;