import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    useEffect(() => {
        if (keycloak.authenticated) {
            navigate('/dashboard');
        }
    }, [keycloak.authenticated, navigate]);

    return (
        <div className="login-container">
            {!keycloak.authenticated && (
                <button 
                    onClick={() => keycloak.login()}
                    className="login-button"
                >
                    Login with Keycloak
                </button>
            )}
        </div>
    );
};

export default Login;