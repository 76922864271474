import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'; // Added Navigate
import LayoutContainer from './layout/components/LayoutContainer';
import AuthProvider from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Login />} />
          
          {/* All other routes are authenticated */}
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <LayoutContainer />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;