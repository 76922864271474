import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import video from '../assets/ai-animation.mp4';
import chevron from '../assets/subtract.svg';

const StyledBackground = styled.div`
    position: relative;
    border-radius: 10px;
    background: #0F054A;
    max-width: 100%;
    margin:0;
    overflow: hidden;
    padding:30px 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height:650px;

    .marginBottom80{
        margin-bottom: 80px;
    }

    h3{
        color: #FFF;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    p{
        color: #FFF;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .indent{
        padding-left: 54px;
    }
`

const StyledCircle = styled.div`
    position: absolute;
    width:42px;
    height:42px;
    border-radius: 50%;
    top:40px;
    left:21px;
    overflow: hidden;

    video{
        position: absolute;
        transform: translate(-50%, -50%);
        width: 96px;
        height:auto;
        left: 50%;
        top: 50%;
    }
`

const StyledInputBlock = styled.div`

    .questions{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom:32px;
    }

    .questions button{
        flex: 1 1 calc(50% - 10px); /* 50% width minus the gap */
        box-sizing: border-box;
        border: none;
        border-radius: 5px;
        background: #1E1265;
        color: #FFF;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding: 8px 10px;
        cursor: pointer;
    }

    form{
        width: 100%;
    }

    input{
        border: none;
        border-radius: 2px;
        background: #25186D;
        color: #FFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        line-height: normal;
        padding:10px 2px;
        border-bottom:1px solid #FFF;
        width:calc(100% - 28px);
    }

    input::placeholder{
        font-style: italic;
        font-weight: 200;
    }

    button{
        position:relative;
        border: none;
        background: transparent;
        top:8px;
        cursor: pointer;
    }
`

const StyledAvatar = styled.div`
    position: absolute;
    background-color: #FF8AF3;
    width:42px;
    height:42px;
    border-radius: 50%;
    line-height: 42px;
    color: #0F054A;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    right:21px;
    top:280px;
    text-align: center;
`

export default function AiChatBox() {
    const [messages, setMessages] = useState([]); // State for chat messages
    const [message, setMessage] = useState('');  // State for input field
    const [conversation, setConversation] = useState([]); // To store all input-response pairs
    const [socketConnected, setSocketConnected] = useState(false);
    const ws = useRef(null);
    const responseScrollRef = useRef(null);

    useEffect(() => {
        // Initialize WebSocket connection when component mounts
        ws.current = new WebSocket('wss://dev.rundis.io/gpt');
        
        ws.current.onopen = () => {
        console.log('WebSocket connected');
        setSocketConnected(true);
        };

        ws.current.onmessage = (event) => {
        console.log('Message received from server:', event.data);
        // Add the response to the conversation
        setConversation((prevConversation) => [
            ...prevConversation,
            { type: 'response', text: event.data },
        ]);
        };

        ws.current.onclose = () => {
        console.log('WebSocket closed');
        setSocketConnected(false);
        };

        ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        };

        // Cleanup WebSocket connection on unmount
        return () => {
        if (ws.current) {
            ws.current.close();
        }
        };
    }, []);

    const handleSubmit = (inputMessage) => {
        if (socketConnected && inputMessage.trim()) {
        // Add the input to the conversation
        setConversation((prevConversation) => [
            ...prevConversation,
            { type: 'input', text: inputMessage },
        ]);
        
        // Send message through WebSocket
        ws.current.send(inputMessage);
        setMessages([...messages, inputMessage]);
        setMessage(''); // Clear input after sending
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit(message); // Send the current message
    };

    const handleButtonClick = (text) => {
        handleSubmit(text); // Send the message from the button
    };

    useEffect(() => {
        if (responseScrollRef.current) {
            responseScrollRef.current.scrollTop = responseScrollRef.current.scrollHeight;
        }
    }, [conversation]);

    return(
        <StyledBackground>
            <div class="marginBottom80">
                <h3>Let’s Start with an Overview</h3>
                <p>
                    This is the place where AI will show the info on the topics that usually user is interested in. The information can be presented in the format of the description with some numbers and respond in diagrams. 
                </p>
                <div id="responseScroll" ref={responseScrollRef}>
                {/* Render the conversation history */}
                {conversation.map((entry, index) => (
                    <p className={entry.type === 'input' ? 'indent avatar-right' : 'avatar-left'} key={index}>
                        {entry.text}
                    </p>
                ))}
                </div>
                <StyledCircle>
                    <video width="100%" height="auto" autoPlay loop muted>
                        <source src={video} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                </StyledCircle>
            </div>
            <StyledInputBlock>
            <div className="questions">
                {/* Add onClick to buttons to send their text */}
                <button onClick={() => handleButtonClick('Is there a way to connect Workday and Salesforce in one workflow to track ownership transfer of leads?')}>
                    Is there a way to connect Workday and Salesforce in one workflow to track ownership transfer of leads? 
                </button>
                <button onClick={() => handleButtonClick('Show me any unusual activity on the servers in the last 30 days. Generate report sorting them starting with highest priority.')}>
                    Show me any unusual activity on the servers in the last 30 days. Generate report sorting them starting with highest priority.
                </button>
                <button onClick={() => handleButtonClick('Provide me a comparison of number of employees in Q1’23 and Q1’24.')}>
                    Provide me a comparison of number of employees in Q1’23 and Q1’24.
                </button>
                <button onClick={() => handleButtonClick('Immediately disable all Adobe licenses of laid off employees in the last 7 days.')}>
                    Immediately disable all Adobe licenses of laid off employees in the last 7 days.
                </button>
            </div>
            <form onSubmit={handleFormSubmit}>
                <input
                    type="text"
                    placeholder="Ask a question..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button type="submit">
                    <img src={chevron} alt="Send" />
                </button>
            </form>
            </StyledInputBlock>
            

        </StyledBackground>
    )
}