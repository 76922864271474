import React, { createContext, useContext, useState } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak } from '../config/keycloak';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [initialized, setInitialized] = useState(false);

    const onKeycloakEvent = (event, error) => {
        console.log('onKeycloakEvent', event, error);
    };

    const onTokens = (tokens) => {
        console.log('onTokens', tokens);
    };

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            onEvent={onKeycloakEvent}
            onTokens={onTokens}
            initOptions={{
                onLoad: 'login-required',  // Forces login if not authenticated
            }}
        >
            {children}
        </ReactKeycloakProvider>
    );
};

export default AuthProvider;