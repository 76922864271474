import React, { useState, useEffect } from 'react';
import filter_icon from '../assets/filter-icon.svg';
import { AiOutlinePlus } from 'react-icons/ai';
import SearchBar from './SearchBarDropDown';
import EventFlyout from './EventFlyout';
import Filters from './Filters';
import PlusDropdown from './PlusDropdown';
import timeline from '../assets/timeline-icon.svg';

export default function HeaderNavItem({ checkboxes, handleCheckboxChange, usage, handleUsageChange, showFlyout, handleShowFlyout, plusMenu, handleShowPlusMenu, showMenu, handleShowMenu, filterMenu, handleFilterMenu, handleOpenAddModal,  showPlusMenu, isModalVisible, setModalVisible}) {
    var [date, setDate] = useState(new Date());
    
    const closePlusMenuOpenModal = () => {
        showPlusMenu(false);
        setModalVisible(true);
    }

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    const day = days[date.getDay()];
    const month = months[date.getMonth()];
    const dateNumber = date.getDate();
    const dateString = `${day}, ${month} ${dateNumber}`
    const options = [
        { value: "People", label: "People", type: "heading"},
        { value: "DemiMoore", label: "Demi Moore", type: "link"},
        { value: "AlbertBrooks", label: "Albert Brooks", type: "link"},
        { value: "JoaquinPhoenix", label: "Joaquin Phoenix", type: "link"},
        { value: "Actions", label: "Actions", type: "heading"},
        { value: "Action1", label: "Action 1", type: "link"},
        { value: "Action2", label: "Action 2", type: "link"},
        { value: "Action3", label: "Action 3", type: "link"},
        { value: "Providers", label: "Providers", type: "heading"},
        { value: "Add This Provider1", label: "Add This Provider", type: "link"},
        { value: "Add This Provider2", label: "Add This Provider", type: "link"},
        { value: "Add This Provider3", label: "Add This Provider", type: "link"}
    ]
    return (
        <div className="header-nav-item">
            <div className="left-nav-items-container">
                <div className="left-nav-item active">
                    <a href="/">All</a>
                </div>
                <div className="left-nav-item">
                    <a href="/">Events</a>
                </div>
                <div className="left-nav-item">
                    <a href="/">Predictions</a>
                </div>
            </div>
            <div className="right-nav-items-container">
                <div className="date-nav-item">
                    <p>{dateString}</p>
                </div>
                <div className="right-nav-item">
                    <Filters filterMenu={filterMenu} handleFilterMenu={handleFilterMenu} checkboxes={checkboxes} handleCheckboxChange={handleCheckboxChange} usage={usage} handleUsageChange={handleUsageChange} />
                </div>
                <SearchBar placeHolder="Search...." options={options} showMenu={showMenu} handleShowMenu={handleShowMenu} />
                <div className={`right-nav-item ${plusMenu ? "active" : ""} ${isModalVisible || plusMenu ? "active-button" : ""}`}>
                    <AiOutlinePlus className="plus-icon font-black" onClick={handleShowPlusMenu} />
                    <div className="overlay__quicklinks">
                        <button className="quick_link" onClick={() => closePlusMenuOpenModal()}>Add Provider</button>
                        <button className="quick_link alarm">Add Trigger</button>
                        <button className="quick_link bolt">Add Action</button>
                        <button className="quick_link network">Add Workflow</button>
                        <button className="quick_link person">Add Person</button>
                        <button className="quick_link plus">Add Custom</button>
                    </div>
                    <PlusDropdown isVisible={isModalVisible} onClose={() => setModalVisible(false)} />
                </div>
                <div className="right-nav-item">
                    <button className="timelineIcon">
                        <img src={timeline} />
                    </button>
                </div>
                <div className="right-nav-item">
                    <EventFlyout showFlyout={showFlyout} handleShowFlyout={handleShowFlyout} />
                </div>
            </div>
        </div>
    )
}