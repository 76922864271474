import Keycloak from 'keycloak-js';

const keycloakConfig = {
    url: 'https://auth.rundis.io/',
    realm: 'dev-rundis-realm',
    clientId: 'localrundisapp',
    redirectUri: window.location.origin + '/dashboard', // Ensure this matches the routes
    postLogoutRedirectUri: window.location.origin + '/login',
    enablePkce: true, 
};

export const keycloak = new Keycloak(keycloakConfig);